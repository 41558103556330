function NoPaymentHeaders() {
  return (
    <tr>
      <th>№</th>
      <th>Способ оплаты</th>
      <th>Сумма</th>
      <th></th>
    </tr>
  );
}

export default NoPaymentHeaders;
