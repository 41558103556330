import React from "react";

function SpendingHeaders() {
  return (
    <tr>
      <th>№</th>
      <th>ID</th>
      <th>Назначение</th>
      <th>Сумма</th>
      <th>Дата</th>
      <th>Заметка</th>
      <th>Пользователь</th>
      <th></th>
    </tr>
  );
}

export default SpendingHeaders;
