function NoGoodHeaders() {
  return (
    <tr>
      <th>№</th>
      <th>Наименование</th>
      <th>Цена</th>
      <th>Количество</th>
      <th>Скидка/Наценка</th>
      <th>Сумма</th>
      <th></th>
    </tr>
  );
}

export default NoGoodHeaders;
